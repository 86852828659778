import React, { Fragment, useState } from "react";
import { Button, Carousel, Input, Modal, Popconfirm, Space } from "antd";
import {
  BsArchiveFill,
  BsPlusCircleFill,
  BsFillBookmarkStarFill,
  BsFillBookmarkDashFill,
  BsFillTrashFill,
} from "react-icons/bs";

import "../Feed/feedTab.css";
import TagsBox from "../../../Components/TagsBox/TagsBox";
import {
  changeAdSequence,
  getAdsFeedLength,
  orderAdSequence,
  reduceAdSequence,
  removePostasAd,
} from "../../../SupabaseServices/ContentDatabase";
import { supabase } from "../../../Config/SupabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import useUpdateTags from "../Feed/useUpdateTags";
import useUpdatePermalink from "../Feed/useUpdatePermalink";
import useUpdateShoplink from "../Feed/useUpdateShoplink";
import useUpdatePostStatus from "../Feed/useUpdatePostStatus";
import Caption from "../Feed/Caption";
import useUpdateTaggedlink from "../Feed/useUpdateTaggedLink";
import useUpdateExternallink from "../Feed/useUpdateExternalLink";
import useDeletePosts from "../../../Utils/CustomHooks/useDeletePosts";

const ContentSearchTable = ({
  usernames,
  activeKey,
  data,
  filter,
  setLimitError,
  refetchData,
  userIds,
  currentPage,
  refectchEmbed,
  refetchNec,
}: any) => {
  console.log(usernames);
  const handleDragStart = (e: any, index: any) => {
    e.dataTransfer.setData("text/plain", index);
  };
  const { mutate: deletePostPermanently } = useDeletePosts();


  const handleDragOver = (e: any, index: any) => {
    e.preventDefault();
  };

  const handleDrop = async (
    e: any,
    index: number,
    post_id: any,
    username: any
  ) => {
    const targetIndex = index;
    const sourceIndex = parseInt(e.dataTransfer.getData("text/plain"));
    orderAdSequence(sourceIndex, targetIndex, post_id).then(() => {
      refetchData();
    });
  };

  const { mutate: updateTags } = useUpdateTags();
  const { mutate: updatePermalink } = useUpdatePermalink();
  const { mutate: updateShoplink } = useUpdateShoplink();
  const { mutate: updateTaggedlink } = useUpdateTaggedlink();
  const { mutate: updateExternallink } = useUpdateExternallink();
  const { mutate: updatePostStatus } = useUpdatePostStatus();

  function removeTag(
    tagArr: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    const idx = tagArr.indexOf(tag);

    tagArr.splice(idx, 1);
    updateTags({
      currentPage,
      activeKey,
      tagString: tagArr.toString(),
      post_id,
      pageIndex,
      index,
      filter,
      usernames,
      action: "remove",
      tag,
      user_id:userIds[0]

    });
    refectchEmbed();
    refetchNec();
    data = data.map((post: any) =>
      post.post_id === post_id ? { ...post, tagg: tagArr.toString() } : post
    );
    console.log("upd new", data);
  }

  function submitTag(
    e: any,
    tagArray: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    e.preventDefault();
    if (e.keyCode === 13) {
      let tagsToProcess = tag.split(",");
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );

      // Stringify the modified array
      const resultString = modifiedArray.toString();
      // tagsToProcess.forEach((oneTag:any) => {
      let arrString = tagArray
        ? [`${resultString}`, ...tagArray.split(",")].toString()
        : `${resultString}`;

      updateTags({
        currentPage,
        activeKey,
        tagString: arrString,
        post_id,
        pageIndex,
        index,
        filter,
        usernames,
        action: "submit",
        tag,
        user_id:userIds[0]

      });
      // });
      refectchEmbed();
      refetchNec();
      data = data.map((post: any) =>
        post.post_id === post_id ? { ...post, tagg: arrString } : post
      );

      e.target.value = "";
    }
  }
  function archivePost(post_id: string, pageIndex: number, postIndex: number) {
    updatePostStatus({
      newPostStatus: "archived",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      usernames,
      userIds,
    });
  }
  function activePost(post_id: string, pageIndex: number, postIndex: number) {
    updatePostStatus({
      newPostStatus: "active",
      postId: post_id,
      activeKey,
      pageIndex,
      postIndex,
      filter,
      usernames,
      userIds,
    });
  }
  function adPost(
    post_id: string,
    pageIndex: number,
    postIndex: number,
    username: any
  ) {
    getAdsFeedLength(usernames).then((adCount: any) => {
      if (adCount + 1 <= 2) {
        updatePostStatus({
          newPostStatus: "active,ad",
          postId: post_id,
          activeKey,
          pageIndex,
          postIndex,
          filter,
          usernames,
          userIds,
        });
        changeAdSequence(post_id, adCount);
        setLimitError(false);
      } else {
        setLimitError(true);
      }
    });
  }

  async function removeFromAds(post_id: any, username: any) {
    removePostasAd(post_id).then(() => {
      refetchData();
      getAdsFeedLength(usernames).then((adCount: any) => {
        reduceAdSequence(username);
        if (adCount + 1 <= 2) {
          setLimitError(false);
        }
      });
    });
  }

  const ActionButtons = ({
    postId,
    pageIndex,
    index,
    postIndex,
    username,
    permalink,
    shoplink,
    postDate,
    taggedlink,
    externallink,
  }: any) => {
    const [newPermalink, setNewPermalink] = useState(permalink);
    const [isEditPermalink, setIsEditPermalink] = useState(false);
    const [newShoplink, setNewShoplink] = useState(shoplink);
    const [isEditShoplink, setIsEditShoplink] = useState(false);
    const [newTaggedlink, setNewTaggedlink] = useState(taggedlink);
    const [isEditTaggedlink, setIsEditTaggedlink] = useState(false);
    const [newExternalLink, setNewExternalLink] = useState(externallink);
    const [isEditExternalLink, setIsEditExternalLink] = useState(false);
    function saveTaggedlink(e: any) {
      e.preventDefault();
      if (e.keyCode === 13) {
        let taggedLink = e.target.value;
        updateTaggedlink({
          currentPage,
          activeKey,
          tagString: taggedLink,
          post_id: postId,
          pageIndex,
          index,
          filter,
          usernames,
        });
      }
      // await supabase.from("content_new").update({permalink:permalink}).eq("post_id",postId)
      // await supabase.from("content_instagram").update({permalink:permalink}).eq("post_id",postId)
    }
    function saveExternalLink(e: any) {
      e.preventDefault();
      if (e.keyCode === 13) {
        let externalLink = e.target.value;
        updateExternallink({
          currentPage,
          activeKey,
          tagString: externalLink,
          post_id: postId,
          pageIndex,
          index,
          filter,
          usernames,
        });
      }
    }
    function saveShoplink(e: any) {
      e.preventDefault();
      if (e.keyCode === 13) {
        let shoplink = e.target.value;
        console.log("dfd", shoplink);
        var encodedUrl = shoplink.match(/u=([^&]+)/)[1];

        // Decoding the URL
        var decodedUrl = decodeURIComponent(encodedUrl);
        const url = new URL(decodedUrl);
        url.searchParams.delete('fbclid'); // Remove fbclid parameter
    
        // Get the URL without fbclid
        const cleanedUrl = url.toString();
        // Output the decoded URL
        // console.log(decodedUrl);

        updateShoplink({
          currentPage,
          activeKey,
          tagString: cleanedUrl,
          post_id: postId,
          pageIndex,
          index,
          filter,
          usernames,
        });
      }
      // await supabase.from("content_new").update({permalink:permalink}).eq("post_id",postId)
      // await supabase.from("content_instagram").update({permalink:permalink}).eq("post_id",postId)
    }

    function savePermalink(e: any) {
      e.preventDefault();
      if (e.keyCode === 13) {
        let permalink = e.target.value;
        console.log("permm", permalink);
        updatePermalink({
          currentPage,
          activeKey,
          tagString: permalink,
          post_id: postId,
          pageIndex,
          index,
          filter,
          usernames,
        });
      }
      // await supabase.from("content_new").update({permalink:permalink}).eq("post_id",postId)
      // await supabase.from("content_instagram").update({permalink:permalink}).eq("post_id",postId)
    }
    function deletePost(post_id: string, pageIndex: number, index: number,group?:any) {
      deletePostPermanently({
        postId: post_id,
        activeKey,
        pageIndex,
        postIndex: index,
        filter,
        user_id: userIds[0],
        username:usernames[0],
        // group

      });
    }
    return (
      <>
        {new Date(postDate).getFullYear() > 2000 ? (
          <p className="creator-feed-content-date">
            {new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              day: "numeric",
              month: "long",
            }).format(new Date(postDate))}
          </p>
        ) : (
          ""
        )}
        {activeKey !== "archived" ? (
          <Button
            type="default"
            icon={<BsArchiveFill className="bookmark-fill" />}
            onClick={() => {
              archivePost(postId, pageIndex, index);
            }}
          >
            Archive
          </Button>
        ) : (
          <Button
            type="default"
            icon={<BsPlusCircleFill className="bookmark-fill" />}
            onClick={() => {
              activePost(postId, pageIndex, index);
            }}
          >
            Active
          </Button>
        )}

        {activeKey !== "archived" || activeKey !== "active,ad" ? (
          <Button
            icon={<BsFillBookmarkStarFill className="bookmark-fill" />}
            onClick={() => {
              adPost(postId, pageIndex, index, username);
            }}
            disabled={!(postIndex === "active")}
          >
            {postIndex === "active" ? "Add to Ads" : "Added to Ads"}
          </Button>
        ) : activeKey === "active,ad" ? (
          <Button
            icon={<BsFillBookmarkDashFill className="bookmark-fill" />}
            onClick={() => {
              removeFromAds(postId, username);
            }}
          >
            Remove from Ads
          </Button>
        ) : (
          ""
        )}
        {activeKey !== "archived" || activeKey !== "active,ad" ? (
          <>
            {permalink ? (
              <>
                {isEditPermalink ? (
                  <input
                    type="text"
                    // id="tagInput"
                    // enterKeyHint="enter"
                    placeholder="Enter permalink here"
                    // className="tagInput"
                    onKeyUp={(e: any) => {
                      savePermalink(e);
                    }}
                    onChange={(e: any) => setNewPermalink(e.target.value)}
                    value={newPermalink}
                  />
                ) : (
                  <p className="break-word">
                    {permalink}
                    <HiPencil onClick={() => setIsEditPermalink(true)} />
                  </p>
                )}
              </>
            ) : (
              <input
                type="text"
                // id="tagInput"
                // enterKeyHint="enter"
                placeholder="Enter permalink here"
                // className="tagInput"
                onKeyUp={(e: any) => {
                  savePermalink(e);
                }}
              />
            )}

            {shoplink ? (
              <>
                {isEditShoplink ? (
                  <input
                    type="text"
                    // id="tagInput"
                    // enterKeyHint="enter"
                    placeholder="Enter shoplink here"
                    // className="tagInput"
                    onKeyUp={(e: any) => {
                      saveShoplink(e);
                    }}
                    onChange={(e: any) => setNewShoplink(e.target.value)}
                    value={newShoplink}
                  />
                ) : (
                  <p className="break-word">
                    {shoplink}
                    <HiPencil onClick={() => setIsEditShoplink(true)} />
                  </p>
                )}
              </>
            ) : (
              <input
                type="text"
                // id="tagInput"
                // enterKeyHint="enter"
                placeholder="Enter shoplink here"
                // className="tagInput"
                onKeyUp={(e: any) => {
                  saveShoplink(e);
                }}
              />
            )}
            {taggedlink ? (
              <>
                {isEditTaggedlink ? (
                  <input
                    type="text"
                    // id="tagInput"
                    // enterKeyHint="enter"
                    placeholder="Enter taggedLink here"
                    // className="tagInput"
                    onKeyUp={(e: any) => {
                      saveTaggedlink(e);
                    }}
                    onChange={(e: any) => setNewTaggedlink(e.target.value)}
                    value={newTaggedlink}
                  />
                ) : (
                  <p className="break-word">
                    {taggedlink}
                    <HiPencil onClick={() => setIsEditTaggedlink(true)} />
                  </p>
                )}
              </>
            ) : (
              <input
                type="text"
                // id="tagInput"
                // enterKeyHint="enter"
                placeholder="Enter taggedLink here"
                // className="tagInput"
                onKeyUp={(e: any) => {
                  saveTaggedlink(e);
                }}
              />
            )}
            {externallink ? (
              <>
                {isEditExternalLink ? (
                  <input
                    type="text"
                    // id="tagInput"
                    // enterKeyHint="enter"
                    placeholder="Enter external Link here"
                    // className="tagInput"
                    onKeyUp={(e: any) => {
                      saveExternalLink(e);
                    }}
                    onChange={(e: any) => setNewExternalLink(e.target.value)}
                    value={newExternalLink}
                  />
                ) : (
                  <p className="break-word">
                    {externallink}
                    <HiPencil onClick={() => setIsEditExternalLink(true)} />
                  </p>
                )}
              </>
            ) : (
              <input
                type="text"
                placeholder="Enter external Link here"
                onKeyUp={(e: any) => {
                  saveExternalLink(e);
                }}
              />
            )}
          </>
        ) : (
          ""
        )}

        <Popconfirm
          title="Are you sure you want to permanently delete this post?"
          onConfirm={() => deletePost(postId, pageIndex, index)}
          okText="Yes"
          cancelText="No"
          overlayStyle={{ maxWidth: "240px" }}
        >
          <Button
            type="primary"
            danger
            icon={<BsFillTrashFill className="iconSize" />}
            style={{ gap: "1px" }}
          >
            Delete Post
          </Button>
        </Popconfirm>
      </>
    );
  };
  return (
    <>
      {/* {data.pages && data.pages[0].length ? (
        <div className="feed-tab-posts-table">
          {data?.pages.map((group: any, pageIndex: number) => ( */}
      <Fragment>
        {data &&
          data.map((image: any, index: number) => {
            return (
              <div
                className={`feed-tab-post-row ${
                  activeKey === "active,ad" ? "feed-tab-post-row-grab" : null
                }`}
                key={index}
                draggable={activeKey === "active,ad" ? true : false}
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => handleDragOver(e, index)}
                onDrop={(e) =>
                  handleDrop(e, index, image.post_id, image.username)
                }
                style={{ marginBottom: "2%" }}
              >
                {/* <>{console.log("imff",image)}</> */}
                <div className="feed-tab-row-media">
                  <div className="feed-tab-row-media-wrapper">
                    {image && image.media_url ? (
                      <>
                        {image.media_url.split(",").length > 1 &&
                        image.media_url
                          .split(",")
                          .every((url: any) => url.trim() !== "") ? (
                          // <ContentPostCarousel image={image} index={index} />
                          <Carousel
                            dotPosition="bottom"
                            className="feed-tab-media-carousel"
                          >
                            {image.media_url
                              .split(",")
                              .map((media_url: any, index: any) => (
                                <React.Fragment>
                                  {media_url.includes("video") ? (
                                    <video
                                      className="feed-tab-media mh-300"
                                      autoPlay
                                      playsInline
                                      muted
                                      loop
                                      poster={
                                        image.thumbnail &&
                                        image.thumbnail.split(",").length &&
                                        image.thumbnail.split(",")[index]
                                          ? image.thumbnail.split(",")[index]
                                          : "/assets/dummy_video.png"
                                      }
                                      // src={media_url}
                                    >
                                      {/* <source src={media_url} /> */}
                                    </video>
                                  ) : (
                                    <img
                                      className="feed-tab-media mh-300"
                                      src={media_url}
                                      alt="post"
                                    />
                                  )}
                                </React.Fragment>
                              ))}
                          </Carousel>
                        ) : (
                          <>
                            {image.media_url.includes("video") ? (
                              <video
                                className="feed-tab-media"
                                autoPlay
                                playsInline
                                muted
                                loop
                                poster={
                                  image.thumbnail
                                    ? image.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                src={image.media_url}
                              >
                                <source src={image.media_url} />
                              </video>
                            ) : (
                              <img
                                className="feed-tab-media"
                                src={image.media_url}
                                alt="post"
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <img
                        className="feed-tab-media"
                        src={
                          image.thumbnail
                            ? image.thumbnail
                            : "/assets/dummy_video.png"
                        }
                        alt="post"
                      />
                    )}
                  </div>
                  <div className="feed-tab-row-actions-mobile">
                    <ActionButtons
                      postIndex={image.index}
                      postId={image.post_id}
                      pageIndex={1}
                      index={index}
                      username={image.username}
                      postDate={image.post_date}
                      taggedlink={image.tagged_link}
                      externalLink={image.external_link}
                      // group={group}
                    />
                  </div>
                </div>
                <div className="feed-tab-row-tags">
                  <div className="search-container-tagInput">
                    <input
                      type="text"
                      id="tagInput"
                      enterKeyHint="enter"
                      placeholder="Enter tags here"
                      className="tagInput"
                      onKeyUp={(e: any) => {
                        submitTag(
                          e,
                          image.tagg,
                          e.target.value,
                          image.post_id,
                          1,
                          1
                        );
                      }}
                    />
                  </div>
                  <div className="tag-box">
                    {image.tagg ? (
                      <TagsBox
                        tagsArray={image.tagg.split(",")}
                        removeTag={(tag: string) => {
                          removeTag(
                            image.tagg.split(","),
                            tag,
                            image.post_id,
                            1,
                            1
                          );
                        }}
                      />
                    ) : (
                      <p className="noTags">No Tags Added yet!</p>
                    )}
                  </div>
                  {image.tags_api ? (
                    <>
                      <div className="api-box">
                        {image.tags_api ? (
                          <>
                            API Tags :-
                            <TagsBox
                              tagsArray={image.tags_api.split(",")}
                              removeTag={(tag: string) => {
                                removeTag(
                                  image.tags_api.split(","),
                                  tag,
                                  image.post_id,
                                  1,
                                  index
                                );
                              }}
                            />
                          </>
                        ) : (
                          <p className="noTags">No Tags generated</p>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="tag-box mt-8">
                    {image.caption ? <Caption caption={image.caption} /> : ""}
                  </div>
                </div>
                <div className="feed-tab-actions">
                  <ActionButtons
                    postIndex={image.index}
                    postId={image.post_id}
                    pageIndex={1}
                    index={index}
                    username={image.username}
                    permalink={image.permalink}
                    shoplink={image.shop_link}
                    postDate={image.post_date}
                    taggedlink={image.tagged_link}
                    externalLink={image.external_link}
                  />
                </div>
              </div>
            );
          })}
      </Fragment>

    </>
  );
};

export default ContentSearchTable;
