import React, { Fragment, useState } from "react";
import {
  Button,
  Carousel,
  Checkbox,
  Input,
  Modal,
  Popconfirm,
  Spin,
} from "antd";
import {
  BsPlusCircleFill,

  BsFillTrashFill,
  BsFillChatDotsFill,
} from "react-icons/bs";

import "./feedTab.css";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TiPin } from "react-icons/ti";
import { FaLock } from "react-icons/fa";
import useUpdateTags from "../Feed/useUpdateTags";
import useUpdatePostStatus from "../Feed/useUpdatePostStatus";
import { changeAdSequence } from "../../../SupabaseServices/ContentDatabase";

import { supabase } from "../../../Config/SupabaseClient";
import useDeletePosts from "./useDeletePosts";


const BrokenLinkContent = ({username,user_id,brokenLinks,refetch}:any) => {



  
  const { mutate: updatePostStatus } = useUpdatePostStatus();
  const { mutate: deletePostPermanently } = useDeletePosts();

 
  async function activePost(
    post_id: string,
    pageIndex: number,
    postIndex: number
  ) {
    await supabase
      .from(`${username}`)
      .update({ shoplink_broken: false })
      .eq("post_id", post_id)
      .eq("user_id", user_id);

    updatePostStatus({
      newPostStatus: "active",
      postId: post_id,
      activeKey: "archived",
      pageIndex,
      postIndex,
      filter: "",
      usernames: [username],
      userIds:[user_id]
    });
    refetch();
  }

  function deletePost(post_id: string, pageIndex: number, index: number) {
    deletePostPermanently({
      postId: post_id,
      activeKey: "archived",
      pageIndex,
      postIndex: index,
      filter: "",
      user_id
    });
  }

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [postIdToUpdateLink, setPostIdToUpdateLink] = useState(null); // State to store the post ID for link update
  const [newLink, setNewLink] = useState(""); // State to store the new link value

  const showModalToUpdateLink = (postId: any) => {
    setPostIdToUpdateLink(postId);
    setIsUpdateModalOpen(true);
  };

  const handleUpdateCancel = () => {
    setPostIdToUpdateLink(null);
    setIsUpdateModalOpen(false);
    setNewLink(""); // Reset new link value when modal is closed
  };

  const updateLink = async () => {
   
    setNewLink("");
    setIsUpdateModalOpen(false);
  };
  const ActionButtons = ({
    postId,
    pageIndex,
    index,
    postIndex,
    platform,
    shop_link,
  }: any) => {
    function testLink() {
      if (shop_link) {
        window.open(shop_link, "_blank");
      }
    }
    return (
      <>
        {postIndex === "archived" || postIndex === "broken_links" ? (
          <Button
            type="default"
            icon={
              <BsPlusCircleFill
                className="circleFill"
              />
            }
            onClick={() => {
              activePost(postId, pageIndex, index);
            }}
          >
            Active
          </Button>
        ) : (
          ""
        )}

        <>
          <p className="shopLink">
            {shop_link}
          </p>
          <Button onClick={testLink} disabled={!shop_link}>
            Test Link
          </Button>
          <Button onClick={() => showModalToUpdateLink(postId)}>
            Update Link
          </Button>{" "}
        </>

        <Popconfirm
          title="Are you sure you want to permanently delete this post?"
          onConfirm={() => deletePost(postId, pageIndex, index)}
          okText="Yes"
          cancelText="No"
          overlayStyle={{ maxWidth: "240px" }}
        >
          <Button
            type="primary"
            danger
            icon={
              <BsFillTrashFill
                className="circleFill"
              />
            }
          >
            Delete Post
          </Button>
        </Popconfirm>
      </>
    );
  };
  return (
    <>
      {/* <DragDropContext
        onDragEnd={(result: any) => {
          onDragEnd(result);
        }}
      >
        <Droppable droppableId="ad">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}

              // Additional styling if needed
            > */}
      {brokenLinks?.pages && brokenLinks?.pages[0].length ? (
        <div className="feed-tab-posts-table">
          {brokenLinks?.pages.map((group: any, pageIndex: number) => (
            <Fragment key={pageIndex}>
              {group.map((image: any, index: number) => (
                // <Draggable
                //   key={image.post_id}
                //   draggableId={image.post_id}
                //   index={index}

                // >
                //   {(provided) => (
                <div
                  // ref={provided.innerRef}
                  // {...provided.draggableProps}
                  // {...provided.dragHandleProps}
                  className={`feed-tab-post-row`}
                  // style={activeKey === "active,ad"?{cursor:'pointer'}:{cursor:'grab'}}
                >
                  <div className="feed-tab-row-media">
                    <div
                      className="feed-tab-row-media-wrapper"
                      style={
                        image.platform === "newsletter"
                          ? {
                              backgroundColor: "#efeded",
                              textAlign: "center",
                            }
                          : {}
                      }
                    >
                      {image.platform !== "newsletter" ? (
                        <>
                          {image.media_url ? (
                            <>
                              {image.media_url.split(",").length>1 &&
                              image.media_url
                                .split(",")
                                .every((url: any) => url.trim() !== "") ? (
                                <Carousel
                                  dotPosition="bottom"
                                  className="feed-tab-media-carousel"
                                >
                                  {image.media_url
                                    .split(",")
                                    .map((media_url: any, index: any) => (
                                      <React.Fragment key={index}>
                                        {media_url.includes("video") ? (
                                          <video 
                                            className="feed-tab-media mh-300"
                                            autoPlay
                                            playsInline
                                            muted
                                            loop
                                            poster={
                                              image.thumbnail &&
                                              image.thumbnail.split(",")
                                                .length &&
                                              image.thumbnail.split(",")[index]
                                                ? image.thumbnail.split(",")[
                                                    index
                                                  ]
                                                : "/assets/dummy_video.png"
                                            }
                                          >
                                            <source src={media_url} />
                                          </video>
                                        ) : (
                                          <img 
                                            className="feed-tab-media mh-300"
                                            src={media_url}
                                            alt="post"
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Carousel>
                              ) : (
                                <>
                                {image.media_url.includes("video") ? (
                                  <video 
                                    className="feed-tab-media mh-300"
                                    autoPlay
                                    playsInline
                                    muted
                                    loop
                                    poster={
                                      image.thumbnail ?
                                      image.thumbnail
                                        : "/assets/dummy_video.png"
                                    }
                                  >
                                    <source src={image.media_url} />
                                  </video>
                                ) : (
                                  <img 
                                    className="feed-tab-media mh-300"
                                    src={image.media_url}
                                    alt="post"
                                  />
                                )}
                                </>
                              )}
                            </>
                          ) : (
                            <img
                              className="feed-tab-media"
                              src={
                                image.thumbnail
                                  ? image.thumbnail
                                  : "/assets/dummy_video.png"
                              }
                              alt="post"
                            />
                          )}
                        </>
                      ) : (
                        <div>
                          <h4 className="mt-7">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(image.post_date))}
                          </h4>
                          <h4> Newsletter</h4>
                        </div>
                      )}
                    </div>
                    <div className="feed-tab-row-actions-mobile">
                      <ActionButtons
                        postIndex={image.index}
                        postId={image.post_id}
                        pageIndex={pageIndex}
                        index={index}
                        shop_link={image.shop_link}
                      />
                    </div>
                  </div>

                  <div className="feed-tab-row-tags">
                    {/* <div className="search-container-tagInput">
                                  <input
                                    type="text"
                                    id="tagInput"
                                    enterKeyHint="enter"
                                    placeholder="Enter tags here"
                                    className="tagInput"
                                    onKeyUp={(e: any) => {
                                      submitTag(
                                        e,
                                        image.tagg,
                                        e.target.value,
                                        image.post_id,
                                        1,
                                        index
                                      );
                                    }}
                                  />
                                </div> */}
                    {/* <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {image.tagg ? (
                                    <TagsBox
                                      tagsArray={
                                        image.tagg
                                          ? image.tagg.split(",")
                                          : [""]
                                      }
                                      removeTag={(tag: string) => {
                                        removeTag(
                                          image.tagg
                                            ? image.tagg.split(",")
                                            : [""],
                                          tag,
                                          image.post_id,
                                          1,
                                          index
                                        );
                                      }}
                                      // page="content"
                                    />
                                  ) : (
                                    <p className="noTags">No Tags</p>
                                  )}
                                </div> */}
                  </div>

                  <div className="feed-tab-actions">
                    <ActionButtons
                      postIndex={image.index}
                      postId={image.post_id}
                      pageIndex={pageIndex}
                      index={index}
                      platform={image.platform}
                      shop_link={image.shop_link}
                    />
                  </div>
                </div>
                //   )}
                // </Draggable>
              ))}
            </Fragment>
          ))}
        </div>
      ) : (
        <p className="">
          <i>No broken links 🥳</i>
        </p>
      )}
      {/* Your Modal component */}

      <Modal
        title="Update Link"
        open={isUpdateModalOpen}
        onOk={updateLink}
        onCancel={handleUpdateCancel}
      >
        <Input
          type="text"
          value={newLink}
          onChange={(e: any) => setNewLink(e.target.value)}
          placeholder="Enter new link"
        />
      </Modal>
      {/* {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext> */}
    </>
  );
};

// export default BrokenLinkContent;
export default BrokenLinkContent;
