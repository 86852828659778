import { Alert, Button } from "antd";
import React, { useState } from "react";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";
import { supabase } from "../../../../Config/SupabaseClient";
import axios from "axios";

const PostContent = (props: any) => {
  const { user } = props;
  const [postId, setPostId] = useState("");
  const [caption, setCaption] = useState("");
  const [permalink, setPermalink] = useState("");
  const [shoplink, setShoplink] = useState("");
  const [tags, setTags] = useState("");

  const [postDate, setPostDate] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const handleUploadFiles = (files: any) => {
    const uploaded = [...uploadedFiles];
    files.some((file: any) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });

    setUploadedFiles(uploaded);
  };
  const handleFileEvent = (e: any) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  async function uploadFiles(user: any) {
    const userData: any = await getUserData(user);
    // uploadedFiles.map(async (file: any) => {
    const file = uploadedFiles[0];
    const mediaType = file.type.split("/")[0];
    const mediaExt = file.type.split("/")[1];
    const postId = Math.floor(
      10000000000000000000 + Math.random() * 9000000000000000000
    );
    let tagsToProcess = tags.split(",");
    const modifiedArray = tagsToProcess.map((word: any) => `|${word.trim()}|`);
    //   let postNumber = Math.floor(Math.random() * 10000 + 1);

    await supabase.storage
      .from("content")
      .upload(`${user}/${mediaType}${postId}.${mediaExt}`, file, {
        contentType: `${file.type}`,
      });
    var { data: url }: any = await supabase.storage
      .from("content")
      .getPublicUrl(`${user}/${mediaType}${postId}.${mediaExt}`);

    if (url !== "undefined") {
      var { data, error }: any = await supabase
        .from("content_instagram")
        .insert([
          {
            post_id: postId,
            user_id: userData.user_id,
            provider_id: userData.id,
            permalink: permalink,
            post_date: postDate.toLocaleString(),
            caption: caption ? caption : " ",
            media_url: url.publicUrl,
            type: mediaType === "image" ? "FEED" : "REELS",
            storage_url: url.publicUrl,
            shop_link: shoplink,
          },
        ])
        .select("*");
      await supabase.from("content_new").insert([
        {
          post_id: postId,
          user_id: userData.user_id,
          caption: caption ? caption : " ",
          permalink: permalink,
          media_url: url.publicUrl,
          type: mediaType === "image" ? "FEED" : "REELS",
          platform: "instagram",
          post_date: postDate.toLocaleString(),
          raw_content_id: data[0].id,
          combined_text: caption ? caption : " ",
          shop_link: shoplink,

          tagg: modifiedArray.toString(),

        },
      ]);

      // const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
      // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;
      const thumbnailUrl = `https://dev.thesearchbubble.com/api/thumbnail`;

      axios.post(
        thumbnailUrl,
        { id: userData.user_id, user: user, from: "content" },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      alert("uploading post");
      await supabase
        .from("user")
        .update({ show_instagram: true, is_instagram_on: true })
        .eq("user_id", userData.user_id);
      setPostId("");
      setPostDate("");
      setPermalink("");
      setShoplink("");
      setCaption("");
      setTags("")
      setUploadedFiles([]);
      const files: any = document.getElementById("fileUpload");

      files.value = "";
      // props.setIsModalOpen(false);
    } else {
      <Alert
        type="error"
        message="Error in uploading Feed"
        showIcon
        className="error-alert"
      />;
    }
    // });
  }
  return (
    <div className="content-wrapper">
      <h5>Upload Post </h5>
      {/* <span className="content-block">
        <p className="input-title">Post Id:-</p>
        <input
          required
          className="post-id-input input-value"
          type="text"
          placeholder="enter post id"
          onChange={(e: any) => setPostId(e.target.value)}
          value={postId}
        />
      </span> */}
      <br />
      <span className="content-block">
        <p className="input-title">Caption:- </p>
        <input
          required
          className="caption-input input-value"
          type="text"
          placeholder="enter caption"
          onChange={(e: any) => setCaption(e.target.value)}
          value={caption}
        />
      </span>
      <br />
      <span className="content-block">
        <p className="input-title">Permalink:-</p>
        <input
          required
          className="permalink-input input-value"
          type="text"
          placeholder="enter permalink"
          onChange={(e: any) => setPermalink(e.target.value)}
          value={permalink}
        />
      </span>
      <br />
      <span className="content-block">
        <p className="input-title">Shoplink:-</p>
        <input
          required
          className="permalink-input input-value"
          type="text"
          placeholder="enter shoplink"
          onChange={(e: any) => setShoplink(e.target.value)}
          value={shoplink}
        />
      </span>
      <br />
      <span className="content-block">
        <p className="input-title">Tags:- (Put all tags with comma at once)</p>
        <input
          required
          className="permalink-input input-value"
          type="text"
          placeholder="enter tags"
          onChange={(e: any) => setTags(e.target.value)}
          value={tags}
        />
      </span>
      <br />
      <span className="content-block">
        <p className="input-title">Post Date:- </p>
        <input
          required
          className="post-date-input input-value"
          type="date"
          placeholder="enter post date"
          onChange={(e: any) => setPostDate(e.target.value)}
          value={postDate}
        />
      </span>
      <br />
      <input
        id="fileUpload"
        type="file"
        accept="application/pdf, image/png, image/jpeg, video/mp4, image/webp"
        onChange={handleFileEvent}
        placeholder={uploadedFiles}
      />
      <Button
        type="primary"
        className="upload-btn primary-color"
        onClick={() => uploadFiles(user)}
      >
        {" "}
        Upload Files{" "}
      </Button>
    </div>
  );
};

export default PostContent;
