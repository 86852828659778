import React, { useState } from "react";
import { supabase } from "../../../../Config/SupabaseClient";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";
import axios from "axios";

const DMsContent = (props: any) => {
  const { username } = props;
  console.log("user", username);
  const [jsonData, setJsonData] = useState(null);

  const handleFileUpload = async (event: any) => {
    const userData: any = await getUserData(username);

    const file = event.target.files[0];
    console.log("Selected file:", file);

    const reader = new FileReader();

    // Callback function for when file reading is complete
    reader.onload = async (e: any) => {
      console.log("File reading completed");
              console.log("fike", e.target.result);

    };

    reader.readAsText(file);
  };

  return (
    <div>
      <h2>DM</h2>
      <input type="file" accept=".json" onChange={handleFileUpload} />
      <div>
     
      </div>
    </div>
  );
};

export default DMsContent;






