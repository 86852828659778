import { useEffect, useState } from "react";
import {
  addAdminRole,
  fetchAllUsernames,
  removeAdminRole,
} from "../../../SupabaseServices/UserDatabase";
import PuffLoader from "react-spinners/PuffLoader";
import { Modal } from "antd";
import { supabase } from "../../../Config/SupabaseClient";
import axios from "axios";
import { useLoadCreators } from "../Creators/useLoadCreators";

const Admin = () => {
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState<any>(true);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [name, setName] = useState<any>();
  const [deleteError, setDeleteError] = useState("");
  // useEffect(() => {
  //   fetchUsers();
  // }, []);
  // async function fetchUsers() {
  //   const users = await fetchAllUsernames();
  //   if (users) {
  //     const obj: any = {};
  //     for (var i = 0; i < users.usersArray.length; i++) {
  //       obj[users.usersArray[i]] = users.roles[i];
  //     }

  //     setUsers(obj);
  //     setLoading(false);
  //   }
  // }
  const { isLoading: userLoading, data: userData,refetch:fetchUsers }: any = useLoadCreators();

  async function removeAdmin(user: any) {
    removeAdminRole(user).then(() => {
      fetchUsers();
    });
  }
  async function addAdmin(user: any) {
    addAdminRole(user).then(() => {
      fetchUsers();
    });
  }
  function deleteUser(user: any) {
    setName(user);
    setOpen(true);
    setModalText(`Are you sure about deleting "${user}" as a creator`);
  }

  const handleOk = async () => {
    let errorFlag: any = [];
    setModalText("Deleting Creator");
    setConfirmLoading(true);
    const { data: user }: any = await supabase
      .from("user")
      .select("user_id")
      .eq("username", name);

    const userId = user[0].user_id;

    const url = `https://dev.thesearchbubble.com/api/user/delete`;
    // const  url=`http://localhost:3001/api/user/delete`;
   const res= await axios.post(
      url,
      { id: userId },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
      fetchUsers();
    }, 500);
  };

  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      {userLoading ? (
        <PuffLoader
          color="#5274f9"
          loading={userLoading}
          className="loader"
          size={150}
        />
      ) : (
        <div className="d-side">
          <div className="details">
            <h1 className="dashboard-mobile-hide">Admin</h1>
            <div className="right-component">
              <table className="table">
                <thead>
                  <tr>
                    <th>Usernames</th>
                    <th>Roles</th>
                  </tr>
                </thead>
                {userData && userData.map((user: any, index: any) => 
                (
                  <tbody key={index}>
                    <tr>
                      <td className="column">{user.username}</td>

                      <td className="column">{user.role}</td>

                      <td>
                        {user.role.includes("admin") ? (
                          <button onClick={() => removeAdmin(user.username)}>
                            Remove admin
                          </button>
                        ) : (
                          <button onClick={() => addAdmin(user.username)}>
                            Add Admin
                          </button>
                        )}
                      </td>
                      <td>
                        <button onClick={() => deleteUser(user.username)}>
                          Delete User
                        </button>
                      </td>
                    </tr>
                  </tbody>
                )
                )}
              </table>
            </div>
          </div>
          <Modal
            title="Deleting User"
            open={open}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <p>{modalText}</p>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Admin;
