import { User } from "@supabase/supabase-js";
import { supabase } from "../Config/SupabaseClient";
import axios from "axios";

export async function signUpEmail(email: any, password: any) {
  var { data, error }: any = await supabase.auth.signUp({
    email: email,
    password: password,
  });
  if (error) {
    return false;
  } else if (data) {
    return email;
  }
}

export async function storeDetails(name: any, email: any) {
  const { data }: any = await supabase
    .from("user")
    .select("*")
    .eq("email", email);

  if (data.length === 0) {
    await supabase
      .from("user")
      .insert([
        {
          name,
          email,
        },
      ])
      .single();
  }
}
export async function loginEmail(email: any, password: any) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email: email,
    password: password,
  });
  if (data.user) {
    const { data } = await supabase
      .from("user")
      .select("name,username")
      .eq("email", email)
      .ilike("role", `%admin%`);

    if (data) {
      if (data.length === 0) {
        return false;
      } else {
        const name = data[0].name;

        const username = data[0].username;
        localStorage.setItem("name", name);

        localStorage.setItem("loggedIn", "true");
        return true;
      }
    }
  } else if (error) {
    return "error";
  }
}

export async function fetchUser(username: any) {
  let { data }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", username);
  if (data.length === 0) {
    return false;
  } else {
    const name = data[0].name;
    const picture = data[0].picture;
    const email = data[0].email;
    const phoneNo = data[0].phoneNo;
    return { name, picture, email, phoneNo };
  }
}

export async function updatePhoneNo(username: any, phoneNumber: any) {
  let { data }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", `${username}`);
  if (data.length > 0) {
    await supabase
      .from("user")
      .update({ phoneNo: `${phoneNumber}` })
      .eq("username", `${username}`);
  }
}

export async function updateName(username: any, name: any) {
  let { data }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", `${username}`);
  if (data) {
    await supabase
      .from("user")
      .update({ name: `${name}` })
      .eq("username", `${username}`);
  }
}
export async function updateEmail(username: any, email: any) {
  let { data }: any = await supabase
    .from("user")
    .select("*")
    .eq("username", `${username}`);
  if (data) {
    await supabase
      .from("user")
      .update({ email: `${email}`, emailSignup: `${email}` })
      .eq("username", `${username}`);
  }
}

export async function fetchAllUsernames() {
  let { data, error }: any = await supabase
    .from("user")
    .select("*")
    .order("username");
  // .ilike('username',`%${query}%`);
  if (data && data.length) {
    return data;
  }
  // if (data.length > 0) {
  //   const usersArray: any = [];
  //   const roles: any = [];
  //   const controls: any = [];
  //   data.map((data: any) => {
  //     usersArray.push(data.username);
  //     roles.push(data.role);
  //     controls.push(data.global_view);
  //   });
  //   return { usersArray, roles, controls };
  // }
}
export async function updateIncludeUser(user: any, toggle: any) {
  // if(toggle===true){
  //   await supabase
  //   .from("user")
  //   .update({ global_view: false })
  //   .eq("global_view", true);
  // }

  //   await supabase
  //   .from("user")
  //   .update({ global_view: toggle })
  //   .eq("username", user);

  // storing user in local storage
  const userStatus = { currentUser: user };
  localStorage.setItem("selectedUser", JSON.stringify(userStatus));
}
// export async function getUserData(username: any) {
//   const { data, error } = await supabase
//     .from("user")
//     .select("*")
//     .eq("username", username);

//   if (data) {
//     const id = data[0].provider_id;
//     const user_id = data[0].user_id;
//     const username = data[0].username;
//     const tiktok_user = data[0].tiktok_user;

//     return { id, user_id, username, tiktok_user };
//   }
// }
export async function getUserData(username: any) {
  const { data, error } = await supabase
    .from("user")
    .select("*")
    .eq("username", username);
  if (data) {
    return data[0];
  }
}

export async function removeAdminRole(username: any) {
  await supabase.from("user").update({ role: "user" }).eq("username", username);
}
export async function addAdminRole(username: any) {
  await supabase
    .from("user")
    .update({ role: "user,admin" })
    .eq("username", username);
}

export async function fetchIncludedUsername() {
  const selectedUser = localStorage.getItem("selectedUser");
  const currentUser = selectedUser ? JSON.parse(selectedUser) : null;
  const { data, error } = await supabase
    .from("user")
    .select("username")
    .eq("username", currentUser.currentUser);

  if (data) {
    const users: any = [];
    data.map((data: any) => {
      users.push(data.username);
    });
    return users;
  }
}
export async function fetchIncludedUsers() {
  const selectedUser = localStorage.getItem("selectedUser");
  const currentUser = selectedUser ? JSON.parse(selectedUser) : null;
  const { data, error } = await supabase
    .from("user")
    .select("user_id")
    .eq("username", currentUser.currentUser);

  if (data) {
    const users: any = [];
    data.map((data: any) => {
      users.push(data.user_id);
    });
    return users;
  }
  // const { data, error } = await supabase
  //   .from("user")
  //   .select("*")
  //   .eq("global_view", true);

  // if (data) {
  //   const users: any = [];
  //   data.map((data: any) => {
  //     users.push(data.user_id);
  //   });
  //   return users;
  // }
}
export async function signUpPhone(phoneNumber: any) {
  const { data, error } = await supabase.auth.signInWithOtp({
    phone: phoneNumber,
  });

  if (error === null) {
    return true;
  } else {
    return false;
  }
}
export async function verifyPhoneNumberSignIn(
  phnWithCode: any,
  token: any,
  loginSuccess: (username: string) => void
) {
  const res = {
    error: false,
    message: "",
  };

  const { data: sessionData, error }: any = await supabase.auth.verifyOtp({
    phone: phnWithCode,
    token,
    type: "sms",
  });

  if (
    error ||
    !sessionData ||
    !sessionData.user.user_metadata ||
    !sessionData.user.user_metadata.username
  ) {
    res.error = true;
    res.message = "Invalid token";
    return res;
  } else {
    localStorage.setItem("phoneCredentials", phnWithCode);
    // Check user is already stored
    const { data }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", sessionData.user.user_metadata.username)
      .ilike("role", "%admin%");

    if (data[0] && data[0].name) {
      loginSuccess(data[0].name);
    } else {
      res.error = true;
      res.message = "You are not authorized as admin";
      return res;
    }
    return res;
  }
}

export async function signupUser(
  username: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNo: any,
  instaUsername: any,
  userId: any,
  password: any,
  user: User
) {
  const res = {
    error: false,
    message: "Error, please try again later or contact our support team.",
  };
  try {
    if (
      !username ||
      !firstName ||
      !lastName ||
      !email ||
      !phoneNo ||
      !instaUsername ||
      !password
    ) {
      res.error = true;
      res.message = "Please fill in all fields";
      return res;
    }

    const name = firstName + " " + lastName;

    // `http://localhost:3001/api/user/delete`;
    // const url = `http://localhost:3001/user/create`;
    const url = `https://dev.thesearchbubble.com/user/create`;
    const createNewUserError = await axios.post(
      url,
      {
        id: userId,
        email: email,
        password: password,
        name: name,
        username: username,
        instaUsername: instaUsername,
        phoneNo: phoneNo,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    // const { error: createNewUserError } = await supabase.from("user").insert([
    // 	{
    // 		name,
    // 		username,
    //     user_id:userId,
    // 		phoneNo,
    // 		email,
    //     instagram_username:instaUsername

    // 	},
    // ]);

    if (createNewUserError.data !== "done") {
      res.error = true;
      if (
        createNewUserError.data.message ===
        'duplicate key value violates unique constraint "user_email_key"'
      ) {
        res.message = "This email is already in use.";
      }
      if (
        createNewUserError.data.message ===
        'duplicate key value violates unique constraint "user_username_key"'
      ) {
        res.message = "This username is already taken. Please try another one.";
      }
      if (
        createNewUserError.data.message ===
        "A user with this email address has already been registered"
      ) {
        res.message = "This email is already in use.";
      }
      console.error(createNewUserError);
      return res;
    } else {
      await supabase
        .from("user")
        .update({ is_username_signup: true })
        .eq("username", username);

      // const url = `http://localhost:3001/createTable`;
      const url = `https://dev.thesearchbubble.com/createTable`;

      axios.post(
        url,
        {
          id: userId,
          user: username,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      return (res.error = false);
    }

    // TODO - for now we just insert email to our table, future work: add the following lines of code to register the email in the auth.user supabase table. This will be done after a user clicks on "verify email" button
    // const { data, error } = await supabase.auth.updateUser({
    // 	email,
    // });
    // await supabase
    //   .from("user")
    //   .update({ is_username_signup: true })
    //   .eq("username", username);
    // return res;
  } catch (error: any) {
    res.error = true;
    res.message =
      error?.message ||
      "Error while creating user, please report this error to our support team.";
    console.error(error);
    return res;
  }
}

export async function signupUserFolder(
  username: string,
  userId: any,
  user: User
) {
  try {
    if (!username) {
      return {
        error: true,
        message: "Please fill in all fields",
      };
    }
    const pwd = "12345";

    const fullName = `${username} ${username}`;
    const email = `${username}@gmail.com`;
    const phoneNo = Math.floor(100000000000 + Math.random() * 900000000000);
    const instaUsername = username;

    const url = `https://dev.thesearchbubble.com/user/create`;
    const createNewUserError = await axios.post(
      url,
      {
        id: userId,
        email,
        password: pwd,
        name: fullName,
        username,
        instaUsername,
        phoneNo,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    if (createNewUserError.data !== "done") {
      const errorMessage = createNewUserError.data.message || "";
      const duplicateEmailMessage =
        'duplicate key value violates unique constraint "user_email_key"';
      const duplicateUsernameMessage =
        'duplicate key value violates unique constraint "user_username_key"';
      const duplicateEmailError = errorMessage.includes(duplicateEmailMessage);
      const duplicateUsernameError = errorMessage.includes(
        duplicateUsernameMessage
      );

      return {
        error: true,
        message: duplicateEmailError
          ? "This email is already in use."
          : duplicateUsernameError
          ? "This username is already taken. Please try another one."
          : "Error, please try again later or contact our support team.",
      };
    } else {
      await supabase
        .from("user")
        .update({ is_username_signup: true })
        .eq("username", username);

      const createTableUrl = `https://dev.thesearchbubble.com/createTable`;
      axios.post(
        createTableUrl,
        {
          id: userId,
          user: username,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      return { error: false };
    }
  } catch (error: any) {
    return {
      error: true,
      message:
        error?.message ||
        "Error while creating user, please report this error to our support team.",
    };
  }
}

export async function storeLinks(
  user_id: any,
  username: any,
  linkName: any,
  link: any
) {
  const { data, error }: any = await supabase
    .from("user")
    .select("*")
    .eq("user_id", user_id);
  const completeLink = linkName + ":-" + link;
  if (data[0].instagram_links) {
    const addedLink = data[0].instagram_links + "," + completeLink;
    const { error } = await supabase
      .from("user")
      .update({ instagram_links: addedLink })
      .eq("user_id", user_id);
    if (error === null) {
      return true;
    }
    return false;
  } else {
    const { error } = await supabase
      .from("user")
      .update({ instagram_links: completeLink })
      .eq("user_id", user_id);
    if (error === null) {
      return true;
    }
    return false;
  }

  // if(error===null){
  // 	return true
  // }
  // return false
}

export async function changeSearchBarText(user_id: any, text: any) {
  const { data, error } = await supabase
    .from("user")
    .update({ search_bar_text: text })
    .eq("user_id", user_id);
}
