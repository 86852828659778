import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";

/**
 * Load content
 */

export function useLoadBrokenLinksContent(
  currentPage: any,
  postsPerPage: number,
  totalPages: any,
  filter: string | null,
  usernames: any,
  userIds: any,
) {
  async function getPaginatedFeed({ pageParam = 1 }) {
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    let data,
      error = null;
 
    const { data: brokenLinks, error: brokenLinksError } = await supabase.rpc(
      "get_brokenlinkstable",
      {
        query_string: filter ? [`%%${filter}%%`] : [],
        userid: userIds,

        username: usernames,

        index_type: "archived",
        start_number: start,
        end_number: end,
      }
    );

    data = brokenLinks;
    if (data && data.length >= 0 && Array.isArray(data)) {
      return data;
    } else if (error) {
      // throw new Error(error);
    } else {
      throw new Error("Failed to fetch posts");
    }
    // }
  }

  return useInfiniteQuery(
    ["paginatedBrokenPosts", currentPage, filter, postsPerPage, userIds, usernames],
    getPaginatedFeed,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),
    }
  );
}
