import {
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Upload,
  UploadProps,
} from "antd";
import React, { Fragment, useState } from "react";
import { BsFillTrashFill } from "react-icons/bs";
// import useDeletePosts from "./useDeletePosts";
import { supabase } from "../../../Config/SupabaseClient";
import { UploadOutlined } from "@ant-design/icons";
import TagsBox from "../../../Components/TagsBox/TagsBox";
import useUpdateTags from "../Feed/useUpdateTags";
import useUpdateProductTags from "./useUpdateProductTags";
import axios from "axios";
import { UpdateCombinedText } from "../../../Utils/HelperFunction/HelperFunction";
import useDeletePosts from "../../../Utils/CustomHooks/useDeletePosts";

const ProductContent = (props: any) => {
  const { username, user_id, posts, refetch, currentPage } = props;
  const { mutate: deletePostPermanently } = useDeletePosts();
  const [selectedPost, setSelectedPost] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [newImage, setNewImage] = useState("");
  const [newUrl, setNewUrl] = useState("");

  const [newName, setNewName] = useState("");

  const [fileList, setFileList] = useState([]);

  async function handleOk() {
    //combined_text
    await supabase
      .from("product_bank")
      .update({
        name: newName,
        image: newImage,
        url: newUrl,
        updated_at: new Date(),
      })
      .eq("user_id", user_id)
      .eq("product_id", selectedPost.table_id)
      .eq("name", selectedPost.table_name);

    await supabase
      .from(`${username}`)
      .update({ caption: newName, shop_link: newUrl, product_name: newName,product_image:newImage,media_url:newImage })
      .eq("user_id", user_id)
      .eq("post_id", selectedPost.table_id)
    UpdateCombinedText(username, user_id, selectedPost.table_id);

    refetch();
    setSelectedPost("");
    setNewName("");
    setNewImage("");
    setNewUrl("");
    setFileList([]);
    setIsEdit(false);
    // const embedUrl = `https://dev.thesearchbubble.com/productbankembed`;
    // // const embedUrl = `http://localhost:3001/productbankembed`;

    // const resThumbnail = axios.post(
    //   embedUrl,
    //   { id: user_id, user: username },
    //   {
    //     headers: {
    //       "content-type": "application/json",
    //     },
    //   }
    // );
  }
  function handleCancel() {
    setSelectedPost("");
    setNewName("");
    setNewImage("");
    setNewUrl("");
    setFileList([]);

    setIsEdit(false);
  }

  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
  };

  async function deletePost(post: any, pageIndex: number, index: number,group:any) {
  

      deletePostPermanently({
        postId: post.table_id,
          activeKey: "active",
        pageIndex,
        postIndex: index,
        filter: "",
        user_id: user_id,
        username:username
      });
    refetch();
  }
  const uploadProps: UploadProps<any> = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      info.file.status = "uploading";

      let fileList: any = [...info.fileList];

      fileList = fileList.slice(-1);

      fileList = fileList.map((file: any) => {
        if (file.response) {
          file.url = file.response.url;
          file.staus = "uploading";
        }
        return file;
      });
      setFileList(fileList);

      setTimeout(() => {
        info.file.status = "done";
      }, 500);
      const storeName = newName.split(" ")[0];

      // const post_id = info.file.name.split("_")[0];
      const fileName = info.file.name;

      const sanitizedFileName = fileName
        .replace(/\s+/g, "-")
        .replace(/[^a-zA-Z0-9\-]/g, "");
      const timestamp = Date.now();

      const post_id = `${sanitizedFileName}-${timestamp}`;
      const { data, error }: any = await supabase.storage
        .from("products")
        .upload(
          `${username}/${storeName}/${info.file.type.split("/")[0]
          }${post_id}.jpeg`,
          info.file.originFileObj,
          {
            contentType: `${info.file.type}`,
          }
        );

      var { data: url }: any = await supabase.storage
        .from("products")
        .getPublicUrl(
          `${username}/${storeName}/${info.file.type.split("/")[0]
          }${post_id}.jpeg`
        );
      setNewImage(url.publicUrl);
      if (info.file.status === "done") {
      } else if (info.file.status === "error") {
      }
    },
  };
  function removeTag(
    tagArr: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    const idx = tagArr.indexOf(tag);

    tagArr.splice(idx, 1);
    updateTags({
      currentPage,
      activeKey: "active",
      tagString: tagArr.toString(),
      post_id,
      pageIndex,
      index,
      filter: "",
      usernames: username,
      action: "remove",
      tag,
      user_id,
    });
  }
  const { mutate: updateTags } = useUpdateProductTags();
  function submitTag(
    e: any,
    tagArray: any,
    tag: any,
    post_id: any,
    pageIndex: number,
    index: number
  ) {
    e.preventDefault();
    if (e.keyCode === 13) {
      let tagsToProcess = tag.split(",");
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );

      const resultString = modifiedArray.toString();

      let arrString = tagArray
        ? [`${resultString}`, ...tagArray.split(",")].toString()
        : `${resultString}`;

      updateTags({
        currentPage,
        activeKey: "active",
        tagString: arrString,
        post_id,
        pageIndex,
        index,
        filter: "",
        usernames: username,
        action: "submit",
        tag,
        user_id,
      });

      e.target.value = "";
    }
  }
  return (
    <>
      {posts?.pages && posts?.pages[0].length ? (
        <div className="feed-tab-posts-table">
          {posts?.pages.map((group: any, pageIndex: number) => (
            <Fragment key={pageIndex}>
              {group.map((image: any, index: number) => (
                <>
                  <Row className="tabel-row">
                    <Col span={10}>
                      <a href={image.table_url} target="_blank">
                        <img src={image.table_image} className="table-image" />
                      </a>
                    </Col>
                    <Col span={8}>
                      <a href={image.table_url} target="_blank">
                        <div>
                          <p>{image.table_name}</p>
                        </div>
                      </a>
                      <div className="feed-tab-row-tags">
                        <div className="search-container-tagInput">
                          <input
                            type="text"
                            id="tagInput"
                            enterKeyHint="enter"
                            placeholder="Enter tags here"
                            className="tagInput"
                            onKeyUp={(e: any) => {
                              submitTag(
                                e,
                                image.table_tags,
                                e.target.value,
                                image.table_id,
                                pageIndex,
                                index
                              );
                            }}
                          />
                        </div>
                        <div className="tag-box">
                          {image.table_tags ? (
                            <TagsBox
                              tagsArray={image.table_tags.split(",")}
                              removeTag={(tag: string) => {
                                removeTag(
                                  image.table_tags.split(","),
                                  tag,
                                  image.table_id,
                                  pageIndex,
                                  index
                                );
                              }}
                            />
                          ) : (
                            <p className="noTags">No Tags Added yet!</p>
                          )}
                        </div>
                        {image.tags_api ? (
                          <>
                            <div className="api-tag">
                              {image.tags_api ? (
                                <>
                                  API Tags :-
                                  <TagsBox
                                    tagsArray={image.tags_api.split(",")}
                                    removeTag={(tag: string) => {
                                      removeTag(
                                        image.tags_api.split(","),
                                        tag,
                                        image.table_id,
                                        pageIndex,
                                        index
                                      );
                                    }}
                                  />
                                </>
                              ) : (
                                <p className="noTags">No Tags generated</p>
                              )}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="add-product-div">
                        <Button
                          className="add-product mb-2"
                          onClick={() => {
                            setSelectedPost(image);
                            setNewName(image.table_name);
                            setNewImage(image.table_image);
                            setNewUrl(image.table_url);
                            setIsEdit(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Popconfirm
                          title="Are you sure you want to permanently delete this post?"
                          onConfirm={() => deletePost(image, pageIndex, index,group)}
                          okText="Yes"
                          cancelText="No"
                          overlayStyle={{ maxWidth: "240px" }}
                        >
                          <Button
                            className="remove-button"
                            icon={<BsFillTrashFill className="bookmark-fill" />}
                          >
                            Remove
                          </Button>
                        </Popconfirm>
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </Fragment>
          ))}
        </div>
      ) : (
        ""
      )}
      <Modal
        title="Edit Product Details"
        open={isEdit}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
      >
        <img src={newImage} className="w-20" />
        <p>{newName}</p>
        <Input
          placeholder="Enter product name"
          value={newName}
          onChange={(e: any) => handleInputChange(e.target.value, setNewName)}
          className="mb-20"
        />
        <Input
          placeholder="Enter link"
          value={newUrl}
          onChange={(e: any) => handleInputChange(e.target.value, setNewUrl)}
          className="mb-20"
        />
        <Upload
          {...uploadProps}
          multiple={false}
          maxCount={1}
          showUploadList={fileList.length > 0 ? true : false}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={!newName}
            defaultValue={newName}
            className="mb-15"
          >
            Click to Upload
          </Button>
        </Upload>
      </Modal>
    </>
  );
};

export default ProductContent;
