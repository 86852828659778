import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";

// Hook for adding or removing tags
export default function useUpdateExternallink() {
  const queryClient = useQueryClient();

  const submitExternallink = async ({
    tagString,
    post_id,
    usernames,
  }: {
    currentPage:any,
    activeKey: string;
    tagString: string;
    post_id: string;
    pageIndex: number;
    index: number;
    filter: string | null;
    usernames: any;
  }) => {
    const { data}: any = await supabase
    .from("content_new")
    .select('*')
    .eq("post_id", `${post_id}`);

    const { error }: any = await supabase
      .from(`${usernames[0]}`)
      .update({ external_link: tagString })
      .eq("post_id", `${post_id}`);
    // const { error: instagramTableError }: any = await supabase
    //   .from("content_instagram")
    //   .update({ external_link: tagString })
    //   .eq("post_id", `${post_id}`);
      const { error: adsError }: any = await supabase
      .from("ads_content")
      .update({ external_link: tagString })
      .eq("post_id", `${post_id}`);
    if (error ) {
      throw new Error(error.message);
    } else {
      return true;
    }
  };

return useMutation(submitExternallink, {
  onMutate: async ({ tagString, post_id, usernames }) => {
    await queryClient.cancelQueries("paginatedPosts");

    // Optimistically update the local data
    const previousData = queryClient.getQueryData(["paginatedPosts"]);

    queryClient.setQueryData(["paginatedPosts"], (oldData: any) => {
      if (oldData) {
        // Find and update the specific post with new permalink
        const newData = oldData.map((page: any) =>
          page.map((post: any) =>
            post.post_id === post_id ? { ...post, external_link: tagString } : post
          )
        );
        return newData;
      }
      return oldData;
    });

    return { previousData }; // Return context for rollback
  },
  onError: (error, variables, context) => {
    // Rollback on error by restoring the previous data
    queryClient.setQueryData(["paginatedPosts"], context?.previousData);
  },
  onSettled: () => {
    // Invalidate and refetch the query to update the data
    queryClient.invalidateQueries("paginatedPosts");
  },
});
}